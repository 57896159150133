import React, { Component } from 'react'
import CardGenerico from '../generic/CardGenerico'
import { Box, Typography, withStyles, TextField, Avatar } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AuthService from '../../services/AuthService';
import { getPerfile } from '../../utils/PedidoUtil';
import CustomButton from '../generic/CustomButton';
import { converterURLImagemLogo } from '../../utils/Utils';
import Logo from "../../imagens/LogoQrpedir.svg";

const StyledRating = withStyles({
    iconFilled: {
        color: '#FFD700',
    },
    iconHover: {
        color: '#fcd600',
    },
})(Rating);

export class avaliacaoEmpresa extends Component {
    constructor(props) {
        super(props);
        const empresa = getPerfile().empresa;
        this.state = {
            pedido: null,
            empresa: empresa,
            avaliacao: {
                nota: null,
                observacao: null,
                empresa: empresa,
                pessoa: null,
                pedido: null
            }
        }
    }

    componentDidMount() {
        this.verificaAvaliacao();
    }

    verificaAvaliacao = () => {
        let service = new AuthService();

        service
            .get(`/avaliacaoEmpresa/getAvaliacao/${this.props.match.params.uuid}`)
            .then(resp => {
                this.setState({ avaliacao: resp })
            })
            .catch(err => {
                console.error(err);
            })
    }

    retornarParaListagem() {
        window.location.replace("/")
    }

    handleAvaliar = () => {
        let service = new AuthService();
        var { avaliacao } = this.state;

        service
            .get(`/pedido/uuid/${this.props.match.params.uuid}`)
            .then(resp => {

                if (resp.pessoa) {
                    avaliacao.pessoa = resp.pessoa
                }
                
                avaliacao.pedido = resp

                service
                    .post("/avaliacaoEmpresa", avaliacao)
                    .then(resp => {
                        this.retornarParaListagem()
                    })
                    .catch(err => {
                        console.error(err);
                        this.retornarParaListagem()
                    })

            })
            .catch(err => {
                console.error(err);
            })
    }

    handleChange = (event) => {
        let { avaliacao } = this.state;

        avaliacao[event.target.name] = event.target.value;
        this.setState({ avaliacao })
    }

    render() {
        const { empresa, avaliacao } = this.state;

        return (
            <CardGenerico
                rightButtonClick={event => this.retornarParaListagem(event)}
                cardContent={
                    <div>
                        <Box p={3} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                            <strong style={{ fontSize: "165%" }}>Avaliação da empresa</strong>

                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ marginTop: "15vh" }}>
                                {empresa.urlLogo ? (
                                    <Avatar alt="Remy Sharp" variant = { empresa.formatoLogo} src={converterURLImagemLogo(empresa.urlLogo)} style={{ width: "100px", height: "100px" }} />
                                ) : (
                                        <img src={Logo} alt="logo" style={{ 'width': "45%", 'height': '60%' }} />
                                    )
                                }
                                <strong style={{ fontSize: "130%", marginTop: "10px" }}>
                                    {empresa.nome}
                                </strong>
                            </Box>

                        </Box>
                    </div>
                }
                cardActions={
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        {avaliacao.id ? (
                            <React.Fragment>
                                <Typography style={{ fontSize: "110%" }}>
                                    Esse pedido ja foi avaliado!
                                </Typography>

                                <StyledRating
                                    name="customized-empty"
                                    size="large"
                                    disabled
                                    value={avaliacao.nota}
                                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                    onChange={e => this.handleChange(e)}
                                    style={{margin: "10px 0"}}
                                />

                                {avaliacao.observacao ? (
                                    <Box p={1} style={{ backgroundColor: "#e8e8e8", width: "100%", margin: "15px 0" }}>
                                        <Typography>
                                            {avaliacao.observacao}
                                        </Typography>
                                    </Box>
                                ) : null}

                                <CustomButton
                                    variant="contained" color="secondary" fullWidth
                                    onClick={this.retornarParaListagem}>
                                    retornar para o Cardapio
                                </CustomButton>

                            </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    <StyledRating
                                        name="customized-empty"
                                        size="large"
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        style={{ marginTop: "15px" }} fullWidth
                                        variant="outlined"
                                        name="observacao"
                                        label="deixe um comentário"
                                        onChange={e => this.handleChange(e)}
                                    />

                                    <CustomButton
                                        style={{ marginTop: "20px" }}
                                        variant="contained" color="secondary" fullWidth
                                        disabled={!avaliacao.nota}
                                        onClick={this.handleAvaliar}>
                                        Enviar avaliação
                                    </CustomButton>
                                </React.Fragment>
                            )}
                    </Box>
                } />
        )
    }
}

export default avaliacaoEmpresa
