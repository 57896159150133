import { Button, Dialog, Box, DialogContent, TextField, MenuItem, DialogActions, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import moment from 'moment';
import AuthService from '../../../services/AuthService';
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';

moment.updateLocale('en', {
    weekdays: 'segunda_terça_quarta_quinta_sexta_sábado_domingo'.split('_'),
    weekdaysShort: 'dom_seg_ter_qua_qui_sex_sáb'.split('_'),
});


export default function Agendamento({ configuracaoAgendamento, handleChangeDiaEHorario, selectedTimestampScheduleParam }) {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        selectedDay: "",
        selectedTime: "",
        horarios: [],
        ErrorAgendamento: null,
        selectedTimestampSchedule: selectedTimestampScheduleParam || null
    })

    const getDiasPedido = () => {
        var maximoDias = configuracaoAgendamento.numeroMaximoDiasAgendamento;
        var arrayDias = [];

        let jsonConfig = JSON.parse(configuracaoAgendamento.jsonConfiguracao)

        for (let index = 0; index <= maximoDias; index++) {
            let currentDate = moment().utc().add(index, 'days') /*Adicionado .utc para padronizar o horário */
            let weekDay = currentDate.isoWeekday();
            let configForCurrentDate = jsonConfig.find(elem => elem.dayOfWeek === weekDay)
            let existTimeForThis = configForCurrentDate.times.length > 0

            var obj = {
                label: "",
                dia: "",
                weekDay: weekDay,
                formatedDate: currentDate.format('DD/MM/YYYY'),
                enabled: existTimeForThis
            }
            if (arrayDias.length < 1) {
                obj.label = `${currentDate.format('DD/MM/YYYY ddd')} (hoje)`;
                obj.dia = index;
            } else if (arrayDias.length === 1) {
                obj.label = `${currentDate.format('DD/MM/YYYY ddd')} (amanhã)`;
                obj.dia = index;
            } else {
                obj.label = `${currentDate.format('DD/MM/YYYY ddd')} (daqui a ${index} dias)`;
                obj.dia = index;
            }

            arrayDias.push(obj);
        }

        return arrayDias;
    }

    const cancelarAgendamento = () => {
        handleChangeDiaEHorario(null, null)
        setState({
            selectedDay: "",
            horarios: [],
            selectedTime: "",
            selectedTimestampSchedule: null
        })
        //configuracaoAgendamento.situacao = "NA";
        setOpen(false)
    }

    const confirmarAgendamento = () => {
        //console.log("CONFIRMAR", state)

        if (!isValidoHorarioAgendamento()) {
            return
        }

        handleChangeDiaEHorario(state.selectedDay, state.selectedTime)
        //configuracaoAgendamento.situacao = "A";
        diaAgendado();
        setOpen(false)
    }

    const diaAgendado = () => {

        var hoje = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
        var diaAgendamento = moment(hoje).utc().add(state.selectedDay, 'days') /*Adicionado .utc para padronizar o horário */
        var horarioArray = state.selectedTime.split(":");
        diaAgendamento.hour(horarioArray[0]);
        diaAgendamento.minute(horarioArray[1]);
        setState({
            ...state,
            selectedTimestampSchedule: diaAgendamento
        })
    }

    const handleChangeDia = (newDay) => {

        if (newDay === undefined) {

            setState({
                selectedDay: null,
                horarios: null,
                selectedTime: null
            })
            return;
        }

        var dia = newDay;
        var hoje = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
        var date = moment(hoje).utc().add(dia, 'days').format("YYYY-MM-DD HH:MM"); /*Adicionado .utc para padronizar o horário */

        var service = new AuthService();
        service
            .post(`/configurarAgendamento/getHorarios`, date)
            .then(resp => {

                let msgErroAgendamento = null
                if (resp.times && resp.times.length > 0) {
                    //
                } else {
                    resp.times = null;
                    msgErroAgendamento = "Nenhum horário disponível para esse dia :("
                }
                setState({
                    ...state,
                    selectedDay: dia,
                    horarios: resp.times,
                    ErrorAgendamento: msgErroAgendamento
                });
            })
    }

    const handleChangeHorario = (horario) => {
        setState({ ...state, selectedTime: horario });
    }

    const isValidoHorarioAgendamento = () => {
        if (state.selectedTime === "") {
            let mensagem = "Selecione um horário para continuar"
            setState({ ...state, ErrorAgendamento: mensagem })

            return false
        }
        setState({ ...state, ErrorAgendamento: null })
        return true
    }

    return (
        <Box display="flex" justifyContent="center">
            {configuracaoAgendamento ? (
                <React.Fragment>

                    {state.selectedTimestampSchedule !== null ? (
                        <Tooltip title="Editar agendamento" placement="top" arrow>
                            <Button onClick={() => setOpen(true)}
                                variant="outlined"
                                color="primary" startIcon={<EditIcon />} >
                                {state.selectedTimestampSchedule !== null ? moment(state.selectedTimestampSchedule).utc().format("DD/MM/YY HH:mm") : "Editar Agendamento" /*Adicionado .utc para padronizar o horário */}
                            </Button>
                        </Tooltip>
                    ) : (
                        <Button onClick={() => setOpen(true)}
                            variant="outlined"
                            color="primary" startIcon={<EventIcon />} >
                            {configuracaoAgendamento.situacao === "NA" ? "Deseja agendar seu pedido?" : "Agende seu pedido"}
                        </Button>
                    )}

                    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)} disableBackdropClick={true}>
                        <DialogContent>
                            <Box py={1}>
                                <TextField
                                    id="selecionarMunicipio"
                                    select
                                    label="Dia do agendamento"
                                    name="data"
                                    onChange={event => handleChangeDia(event.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    value={state.selectedDay}
                                >
                                    <MenuItem disabled value={""}>
                                        - selecionar o dia -
                                    </MenuItem>
                                    {getDiasPedido().map((option) => (
                                        <MenuItem key={option.dia} value={option.dia} disabled={!option.enabled}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>

                            <Box py={1}>
                                <TextField
                                    id="selecionarHorario"
                                    select
                                    label="Selecione o horário"
                                    name="horario"
                                    onChange={event => handleChangeHorario(event.target.value)}
                                    fullWidth
                                    variant="outlined"
                                    helperText={state.ErrorAgendamento}
                                    error={!!state.ErrorAgendamento}
                                    value={state.selectedTime}
                                >
                                    <MenuItem disabled value={null}>
                                        - selecione o horário -
                                    </MenuItem>
                                    {state.horarios?.map((option) => (
                                        <MenuItem key={option} value={option} >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                size="small"
                                onClick={cancelarAgendamento}>
                                {configuracaoAgendamento.situacao === "A" ? "Remover" : "Cancelar"}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={confirmarAgendamento}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            ) : null
            }
        </Box >
    )
}
